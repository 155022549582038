import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, TrackByFunction } from '@angular/core';

import { combineLatest, Observable, scan, Subject } from 'rxjs';
import { delay, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Select, Store } from '@ngxs/store';
import Quill from 'quill';
import QuillVideo from 'quill/formats/video';

import { AuthState } from '@shared/states/auth.state';
import { SIDENAV_DATA, SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';
import {
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpCenterToolTipMaxChar,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { assertStoreData } from '@shared/utilities/store.utilities';
import {
  GetHelpItemData,
  GetHelpItemStatistics,
  IncrementHelpItemStats,
  RevertHelpItemData,
  SelectHelpCenterTeam,
  UpdateHelpCenterMode,
  UpdateHelpItemData,
} from '@shared/modules/help-center/state/help-center.actions';
import { ActionsState } from '@shared/states/actions.state';
import { assertString } from '@shared/utilities/string.utilities';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { parseHelpCenterArticle } from '@shared/modules/help-center/help-center.utilities';
import { AccountState } from '@shared/states/account.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { CONSOLE_ZEFFI_TEAM } from '@admin/shared/states/console-state.models';
import { TeamData, TeamType } from '@shared/models/account.model';
import { AccountManager } from '@shared/services/account-manager.service';
import { PrefsState } from '@shared/states/prefs.state';
import { MarkHelpCenterArticle } from '@shared/states/prefs.actions';

const sizes = ['12px', '14px', '18px', '24px'];

const moduleConfig = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'code-block'],
    [{ align: [] }, { indent: '-1' }, { indent: '+1' }, { header: 1 }, { header: 2 }],
    [
      { size: sizes },
      {
        color: [
          '#fff',
          '#dae2e5',
          '#93a0ab',
          '#60717f',
          '#495c6c',
          '#112539',
          '#0083ff',

          '#cc9256',
          '#b39005',
          '#ef5d65',
          '#e20046',
          '#f00',
          '#18b53a',
          '#9a2cde',
        ],
      },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['image', 'video', 'link'],
  ],
};

export class CleanQuillVideo extends QuillVideo {
  html = null;
}

@Component({
  selector: 'zef-help-center-sidenav',
  templateUrl: './help-sidenav.component.html',
  styleUrls: ['./help-sidenav.component.scss', '../help-article/help-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpCenterSidenav implements OnInit, OnDestroy {
  readonly zeffiTeam = CONSOLE_ZEFFI_TEAM;

  readonly moduleConfig = moduleConfig;

  readonly customOptions = [
    { import: 'attributors/style/size', whitelist: sizes },
    { import: 'attributors/style/align' },
  ];

  readonly customModules = [{ path: 'formats/video', implementation: CleanQuillVideo }];

  @Select(AuthState.isZefAdmin)
  readonly isZefAdmin$!: Observable<boolean>;

  @Select(AccountState.isParentAdmin)
  readonly isParentAdmin$!: Observable<boolean>;

  @Select(HelpCenterState.edit)
  readonly edit$!: Observable<boolean>;

  @Select(HelpCenterState.preview)
  readonly preview$!: Observable<boolean>;

  @Select(HelpCenterState.read)
  readonly read$!: Observable<boolean>;

  @Select(HelpCenterState.language)
  readonly lang$!: Observable<HelpCenterLanguage>;

  @Select(HelpCenterState.selectedTeam)
  readonly selectedTeam$!: Observable<string>;

  @Dispatch()
  readonly revert = (subject: HelpItemSubject) => new RevertHelpItemData(subject);

  @Dispatch()
  readonly selectTeam = (team: string) => new SelectHelpCenterTeam(team);

  readonly helpCenterTeam$ = combineLatest([
    this.store.select(AccountState.isChild),
    this.store.select(AccountState.adminSettings),
    this.store.select(AccountState.isParent),
    this.store.select(AccountState.teamKey),
    this.store.select(HelpCenterState.selectedTeam),
  ]).pipe(
    map(
      ([isChild, adminSettings, isParent, teamKey, selectedTeam]) =>
        selectedTeam || (isChild ? adminSettings.childOf : isParent ? teamKey : this.zeffiTeam),
    ),
    shareRef(),
  );

  readonly isPartnerEdit$ = this.helpCenterTeam$.pipe(map((team) => team !== this.zeffiTeam));

  readonly partnerTeams$: Observable<TeamData[]> = this.store.select(AccountState.userAdminTeams).pipe(
    switchMap((teams) => combineLatest(teams.map((team) => this.am.loadTeam(team)))),
    map((teams) => teams.filter((team) => team.adminSettings?.type === TeamType.Parent)),
    shareRef(),
  );

  readonly showPartnerDropdown$: Observable<boolean> = combineLatest([this.isZefAdmin$, this.partnerTeams$]).pipe(
    map(([isZefAdmin, partnerConsoles]) => isZefAdmin && partnerConsoles.length >= (isZefAdmin ? 1 : 2)),
  );

  readonly canEdit$ = combineLatest([this.isZefAdmin$, this.isParentAdmin$]).pipe(
    map(([isAdmin, isParentAdmin]) => isAdmin || isParentAdmin),
    shareRef(),
  );

  readonly dataUpdate$ = new Subject<Partial<HelpCenterItemData | undefined>>();

  readonly dataUpdates$ = this.dataUpdate$.pipe(
    withLatestFrom(this.lang$),
    scan(
      (data, [update, lang]) => {
        if (!update) {
          return undefined;
        }

        if (!data[lang]) {
          data[lang] = {};
        }

        data[lang] = {
          ...data[lang],
          ...update,
        };

        return data;
      },
      {} as Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>,
    ),
    startWith(undefined),
    shareRef(),
  );

  readonly data$: Observable<HelpCenterItemData> = combineLatest([
    this.lang$.pipe(
      switchMap((lang) =>
        assertStoreData(
          this.store,
          HelpCenterState.data(this.data.subject, lang),
          new GetHelpItemData(this.data.subject, lang),
          (data) => data?.article != null,
        ),
      ),
    ),
    combineLatest([this.dataUpdates$, this.lang$]).pipe(map(([data, lang]) => data?.[lang])),
  ]).pipe(
    map(([data, update]) => ({
      ...data,
      ...(update || {}),
    })),
    map((data) => ({
      ...data,
      parsedArticle: parseHelpCenterArticle(data.article),
    })),
    shareRef(),
  );

  readonly title$: Observable<string> = this.data$.pipe(map((data) => data.title || ''));

  readonly externalLink$: Observable<string> = this.data$.pipe(map((data) => data.externalLink || ''));

  readonly stats$: Observable<{ current: HelpCenterItemStatistics; total: HelpCenterItemStatistics }> = combineLatest([
    this.lang$,
    assertStoreData(this.store, HelpCenterState.stats(this.data.subject), new GetHelpItemStatistics(this.data.subject)),
  ]).pipe(
    map(([lang, stats]) => ({
      current: {
        hovers: stats[lang]?.hovers || 0,
        clicks: stats[lang]?.clicks || 0,
        externalClicks: stats[lang]?.externalClicks || 0,
      },
      total: Object.values(stats).reduce(
        (total, current) => ({
          hovers: total.hovers + (current.hovers || 0),
          clicks: total.clicks + (current.clicks || 0),
          externalClicks: total.externalClicks + (current.externalClicks || 0),
        }),
        {
          hovers: 0,
          clicks: 0,
          externalClicks: 0,
        },
      ),
    })),
    shareRef(),
  );

  readonly loading$ = this.store.select(
    ActionsState.whileAction([GetHelpItemData, GetHelpItemStatistics], { subject: this.data.subject }),
  );

  readonly reverting$ = this.store.select(ActionsState.whileAction(RevertHelpItemData, { subject: this.data.subject }));

  readonly saving$ = this.store.select(ActionsState.whileAction(UpdateHelpItemData, { subject: this.data.subject }));

  readonly languages: HelpCenterLanguage[] = ['en', 'fi'];

  readonly modes = HelpCenterMode;

  readonly maxChars = HelpCenterToolTipMaxChar;

  readonly incremented: `${keyof HelpCenterItemStatistics}-${HelpCenterLanguage}`[] = [];

  readonly quickTipError$: Observable<boolean> = this.data$.pipe(
    map(({ quickTip }) => assertString(quickTip).length > this.maxChars),
    shareRef(),
  );

  readonly trackByPartner: TrackByFunction<TeamData> = (_, team: TeamData) => team.$key;

  constructor(
    private store: Store,
    private sn: SidenavRef,
    private am: AccountManager,
    @Inject(SIDENAV_DATA)
    protected data: { subject: HelpItemSubject; skipStatistics?: boolean },
  ) {
    const Size: any = Quill.import('attributors/style/size');
    Size.whitelist = sizes;
    Quill.register(Size, true);
  }

  ngOnInit(): void {
    this.increment('clicks');
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdateHelpCenterMode({ language: undefined }));

    const timestamp = this.store.selectSnapshot(PrefsState.helpCenterTimestamp(this.data.subject));
    if (this.data.subject && timestamp === 0) {
      this.store.dispatch(new MarkHelpCenterArticle(this.data.subject, Date.now()));
    }
  }

  updateMode(mode: { language?: HelpCenterLanguage; mode?: HelpCenterMode }): void {
    if (mode.language) {
      this.increment('clicks');
    }

    this.store.dispatch(new UpdateHelpCenterMode(mode));
  }

  increment(property: keyof HelpCenterItemStatistics): void {
    if (this.data.skipStatistics) {
      return;
    }

    const lang = getLastValue(this.lang$);
    const key: `${keyof HelpCenterItemStatistics}-${HelpCenterLanguage}` = `${property}-${lang}`;

    if (!this.incremented.includes(key)) {
      this.incremented.push(key);
      this.store.dispatch(new IncrementHelpItemStats(this.data.subject, property, lang));
    }
  }

  onUpdate(): void {
    this.store
      .dispatch(new UpdateHelpItemData(this.data.subject, getLastValue(this.dataUpdates$, {})))
      .pipe(
        tap(() => this.dataUpdate$.next(undefined)),
        delay(1),
      )
      .subscribe(() => this.sn.close());
  }

  onEditorCreated(quill: Quill): void {
    quill.focus();

    setTimeout(() => quill.format('size', '14px'));
  }

  updateData(update: Partial<HelpCenterItemData>): void {
    update = Object.entries(update).reduce(
      (a, [key, value]) => ({
        ...a,
        [key]: typeof value === 'string' ? (value.trim() ? value.trim() : null) : value,
      }),
      {},
    );

    this.dataUpdate$.next(update);
  }
}

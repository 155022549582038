/**
 * Shared module used by saas modules.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayContainer } from '@angular/cdk/overlay';

import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_LEGACY_SLIDE_TOGGLE_DEFAULT_OPTIONS as MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
  MatLegacySlideToggleModule as MatSlideToggleModule,
} from '@angular/material/legacy-slide-toggle';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {
  MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS,
  MatLegacyRadioModule as MatRadioModule,
} from '@angular/material/legacy-radio';
import {
  MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatLegacyCheckboxModule as MatCheckboxModule,
} from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldModule as MatFormFieldModule,
} from '@angular/material/legacy-form-field';

import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { NgxsModule } from '@ngxs/store';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgPipesModule, ShortenPipe } from 'ngx-pipes';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

import { QuillModule } from 'ngx-quill';

/**
 * Shared states used by saas modules.
 */
import { PlanState } from '@shared/states/plan.state';
import { ErrorState } from '@shared/states/error.state';
import { PrefsState } from '@shared/states/prefs.state';
import { SurveyState } from '@shared/states/survey.state';
import { ReportState } from '@shared/states/report.state';
import { AccountState } from '@shared/states/account.state';
import { BillingState } from '@shared/states/billing.state';
import { AuthState } from '@shared/states/auth.state';

/**
 * Shared modules used by saas modules.
 */
import { BasicModule } from '@shared/modules/basic.module';
import { LindaModule } from '@shared/modules/linda.module';
import { LocalesModule } from '@shared/modules/locales.module';
import { TrackingModule } from '@shared/modules/tracking.module';
import { MaskPipe } from '@home/shared/pipes/mask.pipe';
import { YoutubePlayerModule } from '@shared/modules/youtube-player/youtube-player.module';
import { HelpCenterModule } from '@shared/modules/help-center/help-center.module';
import { FoldersState } from './states/folders.state';

@NgModule({
  exports: [
    BasicModule,
    LindaModule,
    LocalesModule,
    TrackingModule,
    HelpCenterModule,

    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,

    HttpClientModule,
    HttpClientJsonpModule,

    AngularFireAuthModule,
    AngularFireDatabaseModule,

    DragDropModule,

    MatMomentDateModule,

    MatCardModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSliderModule,
    MatDialogModule,
    MatDividerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,

    NgPipesModule,
    NgxWebstorageModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,

    MaskPipe,
  ],
  imports: [
    BasicModule,
    LindaModule,
    LocalesModule,
    TrackingModule,
    HelpCenterModule,

    FormsModule,
    CommonModule,
    RouterModule,

    HttpClientModule,
    HttpClientJsonpModule,

    PortalModule,
    DragDropModule,

    MatMomentDateModule,

    MatCardModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSliderModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,

    AngularFireAuthModule,
    AngularFireDatabaseModule,

    NgPipesModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,

    NgxWebstorageModule.forRoot({
      prefix: 'zef',
      separator: '.',
    }),

    NgxsModule.forFeature([
      PlanState,
      ErrorState,
      PrefsState,
      SurveyState,
      FoldersState,
      ReportState,
      AuthState,
      AccountState,
      BillingState,
    ]),

    QuillModule.forRoot(),

    YoutubePlayerModule,
  ],
  declarations: [MaskPipe],
  providers: [
    ShortenPipe,

    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never' } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        autoHeightDisabled: true,
        track: 'vertical',
        appearance: 'standard',
        visibility: 'native',
        trackClickScrollDuration: 0,
      },
    },
  ],
})
export class SharedModule {
  constructor(oc: OverlayContainer, registry: MatIconRegistry) {
    oc.getContainerElement().classList.add('zef-visual');

    registry.registerFontClassAlias('md', 'material-icons');
    registry.registerFontClassAlias('zeficons', 'zef-icons-full');
    registry.setDefaultFontSetClass('zef-icons-full');
    registry.registerFontClassAlias('zefplayer', 'zef-icons-free');
  }
}

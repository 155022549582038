<zef-sidenav [loading]="loading$ | async" [ngSwitch]="edit$ | async" [confirmClose]="!!(dataUpdates$ | async)">
  <ng-container *zefSidenavBanner>
    <div
      *ngIf="showPartnerDropdown$ | async"
      class="z-fx-none z-fx z-fx-start-center z-fx-gap-8 zef-card-8-16"
      [ngClass]="(helpCenterTeam$ | async) === zeffiTeam ? 'zef-bgcolor-ink' : 'zef-bgcolor-partner'"
    >
      <span class="zef-text-p2">
        <ng-container *ngSwitchCase="false" i18n>Displaying help center article for:</ng-container>

        <ng-container *ngSwitchCase="true" i18n>Editing help center article for:</ng-container>
      </span>

      <zef-select
        [value]="helpCenterTeam$ | async"
        [class.zef-color-primary-inverted]="(helpCenterTeam$ | async) === zeffiTeam"
        class="invert-color"
        type="inline-p2"
        (change)="selectTeam($event)"
      >
        <ng-container *ngIf="isZefAdmin$ | async">
          <zef-select-option [value]="zeffiTeam">Zeffi</zef-select-option>
        </ng-container>

        <zef-select-option
          *ngFor="let partner of partnerTeams$ | async; trackBy: trackByPartner"
          [value]="partner.$key"
        >
          {{ partner.name }}
        </zef-select-option>
      </zef-select>
    </div>
  </ng-container>

  <ng-container *zefSidenavTitle>
    <ng-container *ngSwitchCase="true">
      <zef-inline-edit
        *ngIf="title$ | async as title; else directInputTmpl"
        class="z-fx-min-width"
        placeholder="Enter title..."
        i18n-placeholder
        [value]="title"
        (valueChange)="updateData({ title: $event })"
      ></zef-inline-edit>

      <ng-template #directInputTmpl>
        <mat-form-field class="zef-input-big zef-input-inline zef-input-no-padding">
          <input
            matInput
            ngModel
            placeholder="Enter title..."
            i18n-placeholder
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="updateData({ title: $event })"
          />
        </mat-form-field>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="false">{{ title$ | async }}</ng-container>
  </ng-container>

  <ng-template zefSidenavButtonSecondary>
    <div class="z-fx z-fx-end-center">
      <ng-container *ngIf="(isZefAdmin$ | async) && (isPartnerEdit$ | async) === false">
        <div *ngIf="stats$ | async as stats" class="zef-text-p2-muted z-fx-sc z-fx-gap-4">
          <div matTooltip="Hovers" i18n-matTooltip>{{ stats.current.hovers }} ({{ stats.total.hovers }})</div>

          <span>•</span>

          <div matTooltip="Clicks" i18n-matTooltip>{{ stats.current.clicks }} ({{ stats.total.clicks }})</div>

          <span>•</span>

          <div matTooltip="External clicks" i18n-matTooltip>
            {{ stats.current.externalClicks }} ({{ stats.total.externalClicks }})
          </div>
        </div>

        <mat-divider [vertical]="true" class="zef-divider-16"></mat-divider>
      </ng-container>

      <div class="z-fx-sc z-fx-gap-4">
        <mat-button-toggle
          *ngFor="let lang of languages"
          class="zef-icon-toggle"
          [value]="lang"
          [checked]="(lang$ | async) === lang"
          (change)="updateMode({ language: lang })"
        >
          <figure class="zef-flag-small" [ngClass]="'zef-lang-' + lang"></figure>
        </mat-button-toggle>
      </div>

      <mat-divider [vertical]="true" class="zef-divider-16"></mat-divider>
    </div>
  </ng-template>

  <div *zefSidenavButtonPrimary class="z-fx-sc z-fx-gap-4">
    <ng-container *ngIf="read$ | async; else updateBtnTmpl">
      <a
        *ngIf="externalLink$ | async"
        [href]="externalLink$ | async"
        rel="nofollow"
        target="_blank"
        mat-raised-button
        color="ink"
        (click)="increment('externalClicks')"
      >
        <mat-icon>action_academy</mat-icon>

        <span i18n>Read more in Help Center</span>
      </a>
    </ng-container>

    <ng-template #updateBtnTmpl>
      <button
        mat-raised-button
        color="success"
        [disabled]="quickTipError$ | async"
        [loading]="saving$ | async"
        (click)="onUpdate()"
        i18n
      >
        Update
      </button>
    </ng-template>

    <button *ngIf="canEdit$ | async" mat-icon-button color="secondary" [matMenuTriggerFor]="moreMenu">
      <mat-icon>more</mat-icon>

      <mat-menu class="zef-menu-combo" #moreMenu>
        <button
          *ngIf="(edit$ | async) === false"
          mat-menu-item
          class="zef-menu-item-primary"
          (click)="updateMode({ mode: modes.Edit })"
        >
          <mat-icon>edit</mat-icon>

          <span i18n>Edit</span>
        </button>

        <ng-container *ngIf="edit$ | async">
          <button *ngIf="(preview$ | async) === false" mat-menu-item (click)="updateMode({ mode: modes.Preview })">
            <mat-icon>visible_on</mat-icon>

            <span i18n>Preview</span>
          </button>

          <button mat-menu-item (click)="updateMode({ mode: modes.Read })">
            <mat-icon>back</mat-icon>

            <span i18n>Cancel</span>
          </button>
        </ng-container>

        <button
          *ngIf="isPartnerEdit$ | async"
          mat-menu-item
          class="zef-menu-item-alert"
          [disabled]="(data$ | async)?.hasParentData === false"
          [inlineDialogTriggerFor]="revertDataDialog"
        >
          <mat-icon>reset</mat-icon>

          <span i18n>Revert to Zeffi</span>

          <zef-inline-dialog [loading]="reverting$ | async" (confirm)="revert(data.subject)" #revertDataDialog>
            <ng-container *dialogTitle i18n>Revert Help Center Article</ng-container>

            <ng-container *dialogContent i18n>
              Revert the custom Help Center Article data to Zeffi's Article
            </ng-container>

            <ng-container *dialogAction i18n>Revert Article</ng-container>
          </zef-inline-dialog>
        </button>
      </mat-menu>
    </button>
  </div>

  <div *zefSidenavContent class="zef-card-32 z-fx-col ql-snow">
    <ng-container *ngIf="data$ | async as data">
      <div *ngSwitchCase="true" class="z-fx-col z-fx-gap-32">
        <div class="z-fx z-fx-gap-16">
          <div class="help-quick-tip z-fx-col z-fx-gap-16 z-fx-flex">
            <div class="z-fx-sc z-fx-gap-8 zef-text-p2">
              <div i18n>Quick tip</div>

              <div [ngClass]="(quickTipError$ | async) ? 'zef-color-alert' : 'zef-color-success'">
                {{ quickTipTextArea.value.length }}/{{ maxChars }}
              </div>

              <div *ngIf="data.quickTip" class="help-test zef-text-lightest z-fx-sc">
                <div i18n>Test</div>

                <button
                  mat-icon-button
                  [helpTooltip]="data.quickTip"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                >
                  <mat-icon class="zef-icon-small" color="ink-lightest">help_center</mat-icon>
                </button>
              </div>
            </div>

            <mat-form-field
              class="zef-textarea-small zef-textarea-space"
              [class.zef-textarea-error]="quickTipError$ | async"
            >
              <textarea
                placeholder="This is a quick tip about the subject. There is a character limit set to 160 to maintain better readability."
                i18n-placeholder
                matInput
                autoSize
                [autoSizeMinRows]="3"
                [ngModel]="data.quickTip"
                (ngModelChange)="updateData({ quickTip: $event })"
                #quickTipTextArea
              ></textarea>
            </mat-form-field>
          </div>

          <div class="z-fx-col z-fx-gap-16 z-fx-flex">
            <div class="zef-text-p2" i18n>External Help Center link</div>

            <mat-form-field class="zef-input-small zef-input-space">
              <input
                placeholder="https://zeffi.hs-sites.com/en/knowledge/knowledge-base-article"
                i18n-placeholder
                matInput
                [ngModel]="data.externalLink"
                (ngModelChange)="updateData({ externalLink: $event })"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="z-fx-col z-fx-gap-16">
          <div class="zef-text-p2" i18n>Article</div>

          <quill-editor
            class="zef-quill space"
            [customOptions]="customOptions"
            [customModules]="customModules"
            [modules]="moduleConfig"
            [ngModel]="data.article || ''"
            placeholder="Article content..."
            i18n-placeholder
            (onEditorCreated)="onEditorCreated($event)"
            (ngModelChange)="updateData({ article: $event })"
          ></quill-editor>
        </div>
      </div>

      <help-article
        *ngSwitchCase="false"
        class="zef-text-p1 ql-editor"
        [article]="data.parsedArticle | safeHtml"
      ></help-article>
    </ng-container>
  </div>
</zef-sidenav>
